
// Hauptfarben
$wefra-white: #d6f6ff;


$wefra-darkgreen: #23583A;      // wefra Dunkel
$wefra-green: #008e43;          // wefra Main
$wefra-lightgreen: #064d25;     // Hellgrün

$wefra-lightbeige: #86bde3;     // Beige Hell
$wefra-sand: #81d8f1;           // Sand
$wefra-darkbeige: #7aa5f6;      // Beige Dunkel

$wefra-darkgrey: #767472;       // Grau Dunkel
$wefra-black: #232021;          // Schwarz
$wefra-grey: #AFACA9;           // Grau
$wefra-lightgrey: #cdc8c4;      // Hellgrau
$wefra-red: #E44040;            // Rot
$wefra-darkred: #a02c2c;        // Dunkelrot
$wefra-lightred: #FFCBC3;       // Hellrot

// Funktionsfarben
$wefra-active: $wefra-green;      // Aktiv
$wefra-disabled: #A49F9B;       // Deaktiviert
$wefra-error: $wefra-red;         // Fehler
$wefra-blue: #09578e;

$wefra-flashgreen: #99f48b;
$wefra-flashred: #f187bc;

$theme-colors: (
  "darkgreen":    $wefra-darkgreen,
  "green":        $wefra-green,
  "lightgreen":   $wefra-lightgreen,
  "lightbeige":   $wefra-lightbeige,
  "sand":         $wefra-sand,
  "darkbeige":    $wefra-darkbeige,
  "grey":         $wefra-grey,
  "black":        $wefra-black,
  "darkgrey":     $wefra-darkgrey,
  "lightgrey":    $wefra-lightgrey,
  "active":       $wefra-green,
  "disabled":     $wefra-disabled,
  "error":        $wefra-error,
  "red":          $wefra-red,
  "lightred":     $wefra-lightred,
  "blue":         $wefra-blue
) !default;

$success: $wefra-green;
$danger: $wefra-error;
$prefix: 'wefra-';

// misc
$default-transition-duration: .15s;

// Bootstrap Variablen

$body-bg: $wefra-white;
$link-decoration: none;
$border-width: 1px;
// fonts

$font-family-sans-serif: sans-serif;
$font-family-serif: helvetica;

$font-size-base: 1.125rem; // Assumes the browser default, typically `16px` * 1,125 = 18px
$font-size-sm: $font-size-base * 0.77777777;
$font-size-lg: $font-size-base * 1.33333333;

// headings
$headings-font-family: $font-family-sans-serif;
$headings-font-weight: 500;
$headings-color: $wefra-black;
//$h1-font-size: 72px;
//$h2-font-size: 48px;
//$h3-font-size: 32px;
//$h4-font-size: 26px;
//
//$h5-font-size: 22px; // not defined in layout
//$h6-font-size: 18px; // not defined in layout

$tile-heading-font-size: $font-size-lg;

// footer
$footer-bg-color: $wefra-darkgreen;
$footer-text-color: white;
$footer-heading-font-size: 18px;
$footer-font-size: 12px;
$footer-end-font-size: 18px;

// navbar
$navbar-light-color: $wefra-black;
$nav-link-font-size: 18px;
$nav-link-font-weight: 700;
$nav-link-hover-color: $wefra-darkgrey;
$navbar-light-active-color: $wefra-black;
$navbar-toggler-border-radius: 0;
$navbar-toggler-focus-width: 0;

// breadcrumbs
$breadcrumb-bg: $wefra-sand;
$breadcrumb-font-size: 14px;
$breadcrumb-padding-y: 10px;
$breadcrumb-divider-color: $wefra-black;
$breadcrumb-item-padding-x: 30px;
$breadcrumb-margin-bottom: 0;

// buttons
$btn-padding-y: 16px;
$btn-padding-x: 32px;
$btn-font-family: $font-family-sans-serif;
$btn-font-size: 18px;
$btn-line-height: 22px;
$btn-border-radius: 30px;
$btn-font-weight: 600;
//
//$btn-font-size-sm: 18px;
//$btn-border-radius-sm: 30px;
//$btn-padding-y-sm:
//$btn-padding-x-sm

// dropdown
$dropdown-padding-y: 8px;
$dropdown-padding-x: 16px;
$dropdown-font-family: $font-family-sans-serif;
$dropdown-font-size: 18px;
$dropdown-line-height: 24px;
$dropdown-border-radius: 4px;
$dropdown-font-weight: 400;


// form / -fields
$form-feedback-valid-color: $success !default;
$form-feedback-invalid-color: $danger !default;
$input-focus-border-color: $wefra-green;
$input-border-radius: 5px;
$input-color: $wefra-darkgrey;
$input-border-color: $wefra-darkgrey;
$input-bg: #ffffff;
$input-padding-x: 16px;
$input-padding-y: 12px;

// input-group-addon
$input-group-addon-padding-y:           .375rem;
$input-group-addon-padding-x:           .75rem;
$input-group-addon-color:               $input-color;
$input-group-addon-bg:                  $wefra-black;
$input-group-addon-border-color:        $input-border-color;

// checkbox
$form-check-input-border-radius: 2px;
$form-check-input-focus-border: $wefra-black;
$form-check-input-focus-box-shadow: none;
$component-active-color: #ffffff;
$component-active-bg: #565452;
$form-check-input-checked-color: $component-active-color;
$form-check-input-checked-bg-color: $component-active-bg;
$form-check-input-checked-border-color: $form-check-input-checked-bg-color;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>");
//$form-check-input-checked-bg-image: none;

$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-6 -6 12 12'><circle r='4' fill='#{$form-check-input-checked-bg-color}'/></svg>");
//$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>");

// hr
$hr-opacity: 1;
$hr-color: $wefra-grey;
// modal
$modal-inner-padding: 30px;
$modal-content-border-width: 0;

$modal-header-border-color: $wefra-black;
$modal-header-border-width: 0 !default;
$modal-header-padding-y: $modal-inner-padding !default;
$modal-header-padding-x: $modal-inner-padding !default;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x 16px $modal-header-padding-x !default;

// tooltips
$tooltip-font-size:                 $font-size-base;
$tooltip-color:                     $wefra-black;
$tooltip-bg:                        white;
$tooltip-max-width:                 600px;

// cards
$card-cap-bg: transparent;

// toasts
// scss-docs-start toast-variables
$toast-max-width:                   450px !default;
//$toast-padding-x:                   .75rem !default;
//$toast-padding-y:                   .5rem !default;
$toast-font-size:                   1rem !default;
//$toast-color:                       null !default;
//$toast-background-color:            rgba(var(--#{$prefix}body-bg-rgb), .85) !default;
//$toast-border-width:                var(--#{$prefix}border-width) !default;
//$toast-border-color:                var(--#{$prefix}border-color-translucent) !default;
$toast-border-radius:               11px !default;
//$toast-box-shadow:                  var(--#{$prefix}box-shadow) !default;
//$toast-spacing:                     $container-padding-x !default;

//$toast-header-color:                var(--#{$prefix}secondary-color) !default;
//$toast-header-background-color:     rgba(var(--#{$prefix}body-bg-rgb), .85) !default;
//$toast-header-border-color:         $toast-border-color !default;
// scss-docs-end toast-variables

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: 0
) !default;
// scss-docs-end grid-breakpoints


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
) !default;
// scss-docs-end container-max-widths
