wefra-edit-grid {
  table.table {
    //table-layout: fixed;
    margin: 0 -0.5rem;
    width: calc(100% + 1rem);
    tbody {
      tr {
        &.success, &.success td  {
          animation: flash-success 1500ms ease-in-out;
        }
        &.error, &.error td {
          animation: flash-error 1500ms ease-in-out;
        }
        &.loading, &.loading td {
          animation: flash-success 1500ms ease-in-out;
        }
      }
    }
  }

  .actions {
    .wrapper {
      float: right;
    }
    .pointer {
      margin-left: 0.5rem;
      &.delete {
        color: $wefra-darkred;
        &:hover {
          color: $wefra-red;
        }
      }

      &.save {
        color: $wefra-darkgreen;
        &:hover {
          color: $wefra-green;
        }
      }
    }
  }

  table td {
    &.nopadding {
      padding: 0;
    }
  }
}

wefra-edit-grid-field-renderer {
  .wrapper {
    padding: 0.5rem;
    display: block;
  }
}
