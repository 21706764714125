wefra-navigation {
  .navbar-nav {
    margin-left: auto;
    flex-direction: row;
    li.nav-item {
      padding: $grid-gutter-width;
      .nav-link {
        &:hover {
          color: $wefra-darkgrey;
        }
        i-bs {
          display: block;
          float:left;
          svg {
            height: 1.7em;
            width: 1.7em;
          }

          &[name='arrow-clockwise'] {
            animation: spin 1s infinite linear;
          }

        }
        span {
          display: block;
          float:left;
          margin-left: 10px;
          margin-top: 3px;
        }

        &.export-ready {
          color: $wefra-green;
          i-bs {
            stroke: $wefra-green;
          }
        }

      }
    }
  }
}
