wefra-crud-grid,
wefra-mini-grid {
  table.editions {
    height: 1px;
    td.capture_state {
      wefra-crud-grid-field-renderer {
        height: 100%;
        width: 100%;
        display: block;
        font-weight: bold;
        span span {
          padding: 5px;
        }
        span, span span {
          display: block;
          height: 100%;
          width: 100%;
        }
        .capturing {
          background-color: $yellow-500;
          color: black;
        }

        .complete {
          background-color: $green-500;
          color: white;
        }

        .scanned {
          background-color: $red-500;
          color: white;
        }
      }
    }
  }
  &.alert-items {
    tr {
      td {
        background-color: #e7c6c6;
      }
      &.empty td {
        background-color: lightgreen;
      }

    }
  }
}

