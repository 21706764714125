wefra-edit-grid {
  table td {
    &.nopadding {
      padding: 0;
    }
  }
  &-field-renderer {
    .wrapper {
      padding: 0.5rem;
      display: block;
      &.success {
        animation: flash-success 1500ms ease-in-out;
      }
      &.error {
        animation: flash-error 1500ms ease-in-out;
      }
      &.loading {
        animation: flash-success 1500ms ease-in-out;
      }
    }
  }
}
