wefra-mini-grid,
wefra-edit-grid {
  .top-container {
    display: flex;
    flex-direction: row;

    &.with-controls {
    }

    &.with-heading {
    }

    .heading, .controls {
      flex: 1;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }

    .heading {
      font-weight: bold;
      font-size: 1.40625rem;
    }

    .controls {
      flex-grow: 1;

      .control {
        float: right;

        color: $wefra-darkgreen;

        &:hover {
          color: $wefra-green;
        }
      }
    }
  }

  table {
    .actions {
      .delete {
        color: $wefra-darkred;
        &:hover {
          color: $wefra-red
        }
      }
    }
  }
}

wefra-crud-grid,
wefra-mini-grid {
  table.editions {
    height: 1px;
    td.capture_state {
      wefra-crud-grid-field-renderer {
        height: 100%;
        width: 100%;
        display: block;
        font-weight: bold;
        span span {
          padding: 5px;
        }
        span, span span {
          display: block;
          height: 100%;
          width: 100%;
        }
        .capturing {
          background-color: $yellow-500;
          color: black;
        }

        .complete {
          background-color: $green-500;
          color: white;
        }

        .scanned {
          background-color: $red-500;
          color: white;
        }
      }
    }
  }
  &.alert-items {
    tr {
      td {
        background-color: #e7c6c6;
      }
      &.empty td {
        background-color: lightgreen;
      }

    }
  }
}

