wefra-leftnav {
  .navbar-nav {
    flex-direction: column;
    li.nav-item {

    }
  }
  li.nav-item {
    list-style-type: none;
    .nav-link {
      padding-right: $grid-gutter-width;
      border-bottom: 1px solid transparent;

      &.active {
        border-bottom: 1px solid $wefra-black;
        &:hover {
          color: $wefra-darkgrey;
        }
      }
      &:hover {
        color: $wefra-darkgrey;
        border-bottom: 1px solid $wefra-darkgrey;
      }
    }
  }
  .nav-title {
    padding: $grid-gutter-width $grid-gutter-width calc($grid-gutter-width / 2) $grid-gutter-width;
    font-weight: bold;
    display: block;
  }

  ul.navbar-nav {
    li.nav-item {
      border-bottom: 1px solid $wefra-grey;
      &:last-child {
        border-bottom: none;
      }
      ul {
        li.nav-item {
          border-bottom: none;

          &:last-child {
            margin-bottom: $grid-gutter-width;
          }
        }
      }
    }
  }
}
