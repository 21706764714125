wefra-mini-grid {
  .body {
    overflow-x: scroll;
    display: block;
  }
  table.editions {
    thead th.capture_state {
      visibility: hidden;
      font-size: 0;
      width: 1px;
    }
    tbody td.capture_state {
      .html span {
        color: transparent;
      }
    }
  }
}
