@keyframes flash {
  0% {
    background-color: var(--wefra-table-bg);
  }
  50% {
    background-color: goldenrod;
  }
  100% {
    background-color: var(--wefra-table-bg);
  }
}

wefra-ad-spendings-capture {
  input.form-control {
    &.flash:hover, &.flash {
      animation: flash 500ms ease-in-out;
    }
  }
}

wefra-ad-spendings-capture-grid {
  tr {
    cursor: pointer;

    &.flash:hover, &.flash {
      td {
        animation: flash 500ms ease-in-out;
      }
    }
  }

  .total {
    margin-bottom: 10px;
    margin-top: 20px;
    float: right;
  }
}

wefra-pdf-pagination,
wefra-crud-grid-pagination {
  input {
    font-size: var(--wefra-pagination-font-size);
    border-radius: 1px;
    border: 1px solid var(--wefra-pagination-border-color);
    padding: 0;
  }
}

wefra-pdf-pagination {
  input {
    text-align: center;
    width: 4ch;
  }
}

wefra-crud-grid-pagination {
  input {
    text-align: right;
    width: 5ch;
  }
}

wefra-crud-grid {

  .full-width-row {
    width: 100%;
    overflow-x: scroll;

    .wrap {
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: 10px;
      column-gap: 2em;
      display: flex;

      .item {
        flex: 1;

        .header {
          display: block;
          font-weight: bold;
          border-bottom: 1px solid $wefra-black;
          margin-bottom: 0.4em;
          padding: 0.2em 0.2em;
        }

        .content {
          table.json {
            td {
              padding: 0.2em 0.4em;
              &.op {
                white-space: nowrap;
                &:after {
                  content: ":";
                }
              }
            }

          }
        }
      }
    }


    &, &:hover {
    td {
      //background-color: $wefra-white;
      wefra-json-renderer {
        table.json {
          tr {
            &.even {
              td {
                background-color: $wefra-lightbeige;
              }
            }

            &.odd {
              td {
                background-color: $wefra-lightgrey;
              }
            }
          }
        }
      }
    }
    }
    .wrap {
      .content {
        flex: 1;
      }
    }
  }

  table.editions {
    height: 1px;

    td.capture_state {
      wefra-crud-grid-field-renderer {
        height: 100%;
        width: 100%;
        display: block;
        font-weight: bold;

        span span {
          padding: 5px;
        }

        span, span span {
          display: block;
          height: 100%;
          width: 100%;
        }

        .capturing {
          background-color: $yellow-500;
          color: black;
        }

        .complete {
          background-color: $green-500;
          color: white;
        }

        .scanned {
          background-color: $red-500;
          color: white;
        }
      }
    }
  }
}

wefra-ad-spendings-capture-grid,
wefra-crud-grid {
  table {
    &.ad_spendings {
      th, td {
        &.actions {
          width: 150px;
        }
      }
    }
  }

  i-bs[name='list-check'].active {
    svg {
      fill: $wefra-green;
    }
  }

  .pagination-wrapper {
    display: flex;
    flex-direction: row;
    padding-right: $grid-gutter-width;

    .total {

    }

    .pager {
      flex-grow: 1;
      margin-left: auto;
    }

  }

  .controls {
    display: block;
    margin-bottom: $grid-gutter-width;
    margin-left: $grid-gutter-width;

    i-bs {
      margin-right: 1rem;
    }
  }

  .table-wrap {
    padding: $grid-gutter-width;
    overflow-x: scroll;
    background-color: $wefra-white;
  }

  table {
    td {
      &.id-property {
        width: 2em;
        white-space: nowrap;
      }
    }

    tr {
      &:hover {
        td {
          background-color: $wefra-lightgrey
        }
      }

      &.select-only {
        td {
          cursor: pointer;
        }
      }

      &.selected {
        td {
          background-color: $wefra-darkbeige;
        }
      }

      &.selected:hover {
        td {
          background-color: $wefra-grey;
        }
      }
    }

    thead {
      select, input {
        border: none;
        background-color: white;
      }

      select {
        padding: 4.4px 0 5px 0;
        width: 100%;
      }

      .title-icon-wrap {

        white-space: nowrap;
        display: flex;

        .title {
          white-space: nowrap;
          padding: 0 0.5em;
          flex-grow: 1;
        }

        i-bs, .title {
          flex: 1;
        }

        i-bs {
          margin-top: -2px;
          flex-grow: 0;

          &.clear-filter {
            margin-left: auto;
            text-align: right;
            cursor: default;
            color: $wefra-disabled;

            &.active {
              color: $wefra-red;
              cursor: pointer;
            }
          }
        }

        //i-bs {
        //  position: absolute;
        //
        //  &.sort {
        //    left: 3px;
        //    top: -2px;
        //  }
        //  &.clear-filter {
        //    right:0;
        //    top:-2px;
        //  }
        //}

      }

      input {
        width: 100%;
      }

      i-bs {
        cursor: pointer;

        svg {
          height: 16px;
          width: 16px;
        }

        &.active {
          color: $wefra-green;
        }
      }
    }

    .actions {
      width: 150px;

      .pointer {
        margin-right: calc($grid-gutter-width / 2);
        margin-bottom: calc($grid-gutter-width / 2);
        display: block;
        float: left;
      }

      .edit {
        color: $wefra-darkgreen;

        &:hover {
          color: $wefra-green;
        }
      }

      .delete {
        color: $wefra-darkred;

        &:hover {
          color: $wefra-red;
        }
      }
    }
  }
}

wefra-crud-grid-field-renderer {
  display: block;
  width: 100%;
  height: 100%;

  img {
    max-height: 100px;
  }

  .html {
    width: 100%;
    height: 100%;
    display: block;
    text-align: left;

    svg {
      margin: 2px auto;
    }
  }
}

wefra-crud-grid-json-renderer {
  display: block;

  .json-button {
    display: inline-block;
    &.expanded {
      background-color: $wefra-white;
      .btn {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .card {
    border-top-left-radius: 0;
    border-color: $wefra-black;
    margin-top: -1px;
  }
}
