@import "styles.variables";

/* Importing Bootstrap SCSS files. */

// scss-docs-start import-stack
// Configuration
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/variables-dark";
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities";

// Layout & components
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
// @import "../../node_modules/bootstrap/scss/buttons";
@import "./bootstrap/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/accordion";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/toasts";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/offcanvas";
@import "../../node_modules/bootstrap/scss/placeholders";

// Helpers
@import "../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../../node_modules/bootstrap/scss/utilities/api";


@keyframes flash-success {
  0% {
    background-color: var(--wefra-table-bg);
  }
  50% {
    background-color: $wefra-flashgreen;
  }
  100% {
    background-color: var(--wefra-table-bg);
  }
}

@keyframes flash-error {
  0% {
    background-color: var(--wefra-table-bg);
  }
  50% {
    background-color: $wefra-flashred;
  }
  100% {
    background-color: var(--wefra-table-bg);
  }
}


// Custom Styles
@import "./components/navigation";
@import "./components/leftnav";
@import "./components/abstract-grid";
@import "./components/crud-grid";
@import "./components/mini-grid";
@import "./components/edit-grid";

.pagewrap {
  header {
  }

  .main {
    overflow: hidden;
    max-width: 100vw;
    background-color: $wefra-lightbeige;
    display: flex;

    .table-wrap {
      overflow-x: scroll;
    }

    .leftnav-container {
      background-color: $wefra-lightbeige;
      width: 15rem;
      float: left;
    }

    .application-container {
      float: left;
      width: calc(100vw - 15rem);
      padding-top: $grid-gutter-width;
      background-color: $wefra-sand;
    }
  }
}

input.form-control {
  &[readonly] {
    background-color: $wefra-grey;
    color: white;
  }
}

.form-field-error {
  color: white;
  font-weight: bold;
  padding: 5px 15px;
  background-color: rgba(255, 0, 0, 1)
}

wefra-toasts {
  color: white;
}

wefra-login {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner-border {
  margin-top: 10%;
}

.pointer {
  cursor: pointer;
}

@keyframes spin {
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }
}

form {
  label {
    display: block;
    width: 100%;

    .tablename {
      display: none;
    }

    svg {
      float: right;
      display: block;
      height: 20px;
      width: 20px;
    }
  }
}

wefra-ad-spendings-capture {
  display: block;
  overflow: hidden;

  .image-container {
    display: block;
    overflow: hidden;
    width: 100%;
  }

  .pullout-link {
    cursor: pointer;
    overflow: hidden;
    display: flex;

    i-bs {
      margin-right: 1em;

      svg {
        height: 1.2em;
        width: 1.2em;
      }
    }

    strong {
      margin-top: 2px;
    }
  }
}

.cost-final {
  input {
    padding-right: 52px;
  }

  i-bs {
    &[name='arrow-clockwise'] {
      animation: spin 1s infinite linear;
    }

    &[name='coin'] {
      right: 16px;

      :hover {
        color: goldenrod;
      }
    }
  }
}


wefra-multi-form-field .dlselector {
  &.norecord {
    cursor: pointer;
  }

  min-height: 53px;

  dl {
    margin-bottom: 0;
  }

  i-bs {
    color: black;
  }
}

.function-field-wrap,
wefra-multi-form-field .dlselector,
wefra-record-value-select-form-field,
wefra-constraint-form-field {
  position: relative;
  display: block;

  i-bs {
    &[name='card-list'] {
      right: 16px;
    }

    &[name='arrow-clockwise'] {
      right: 52px;
    }

    &.clear, &[name='arrow-clockwise'], &[name='card-list'], &[name='coin'] {
      position: absolute;
      top: 12px;
    }

    &.clear {
      right: 52px;
    }

    svg {
      height: 1.5em;
      width: 1.5em;
      cursor: pointer;
    }
  }
}

wefra-constraint-form-field {
  .list {
    z-index: 1000;
    position: absolute;
    background-color: white;
    width: 100%;
    padding: 12px 16px;

    .items {
      max-height: 320px;
      overflow-y: scroll;

      .item {

        cursor: pointer;
        border: 1px solid white;
        border-radius: 24px;
        padding: calc($grid-gutter-width / 2);

        &.active, &:hover {
          background-color: $wefra-lightbeige
        }
      }
    }
  }
}

ngb-modal-window {
  .btn-close:focus {
    box-shadow: none;
    opacity: 1;
  }

  ngbd-modal-content {
    hr.divider {
      margin: 0 $grid-gutter-width;
    }
  }

  &.advanced-edit {
    .modal-dialog {
      min-width: 900px;

      .modal-content {
        min-width: 900px;
        overflow-x: scroll;
      }
    }
  }

  &.alias-select,
  &.historicize-preview {
    .modal-dialog {
      min-width: 800px;

      .modal-content {
        min-width: 800px;
        overflow-x: scroll;
      }
    }
  }

  &.grid-modal {
    .modal-dialog {
      max-width: 95%;

      .modal-body {
        overflow-x: scroll;
        padding: 0;
      }

      .modal-header {
        padding-bottom: 0;
        padding-top: $grid-gutter-width;
      }
    }
  }

  &.editor-modal {
    .modal-dialog {
      .modal-content {
        ngbd-modal-content {
          .modal-header {
            border-bottom: 1px solid $wefra-grey;

            button {
              --wefra-btn-close-focus-shadow: undefined;
            }
          }
        }
      }
    }
  }
}

wefra-monitor-alias-component {
  .table-wrap {
    overflow-y: scroll;
  }

  p {
    padding: 0 5px 0 5px;
  }
}

wefra-toasts {
  ngb-toast {
    .toast-body {
      border-left: 15px solid $wefra-blue;
      border-radius: 10px 0 0 10px;
      display: flex;
      flex-direction: row;
      padding-right: 32px;

      .left {
        margin: auto 16px auto 6px;

        svg {
          width: 28px;
          height: 28px;
        }
      }

      .right {
        flex-fill: 1;
        color: $wefra-black;
      }

      svg.close {
        display: block;
        right: 28px;
        position: absolute;
        top: 34px;
      }
    }

    &.error {
      .toast-body {
        border-left-color: $wefra-error;
      }
    }

    &.success {
      .toast-body {
        border-left-color: $wefra-green;
      }
    }

    &.info {
      .toast-body {
        border-left-color: $wefra-blue;
      }
    }
  }
}

wefra-ad-spendings-upload {
  .file-input {
    background-color: #ff5252;
    border-radius: 50%;
    height: 3rem;
    overflow: hidden;
    position: relative;
    width: 3rem;

    &::after {
      content: '⇪';
      color: #fff;
      font-size: 1.5rem;
      text-align: center;
    }

    input[type="file"] {
      font-size: 100px;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
    }
  }
}

wefra-date-time-field {
  display: block;
  overflow: hidden;

  .inputs-wrap {
    background-color: #eee;
    display: flex;
    overflow: hidden;

    .input-wrap {
      float: left;
      padding-right: 1em;
      position: relative;
      width: 2.5em;
      background-color: white;

      input {
        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        &[type=number] {
          -moz-appearance: textfield;
        }
      }

      &:after {
        position: absolute;
        right: 0;
      }

      &.year {
        width: 3.5em;
        background-color: $wefra-white;
        padding-right: 0.25em;

        &:after {
          content: ' ';
          width: 0.25em;
          display: block;
          height: 100%;
          top: 0;
        }
      }

      &.month {
        &:after {
          content: '.';
          width: 1em;
        }
      }

      &.day {
        &:after {
          content: '.';
          width: 1em;
        }
      }

      &.hour {
        &:after {
          content: ':';
          width: 1em;
        }
      }

      &.minute {
        padding-right: 0;
        width: 1.5em;
      }
    }

    // alternate
    &.show-second {
      &.minute {
        width: 2em;
        &:after {
          content: ':';
          width: 1em;
        }
      }
    }
  }
}

wefra-dashboard {
  display: block;

  h1, .dashboard-tiles .tile {
    background-color: $wefra-white;
  }

  h1, h2 {
    margin: 0 $grid-gutter-width;
    padding: $grid-gutter-width;
    padding-left: $grid-gutter-width;
  }

  .table.ad_spendings .date {
    white-space: nowrap;
  }

  .dashboard-tiles {
    margin: $grid-gutter-width 0 $grid-gutter-width $grid-gutter-width;

    .tile {

      h6 {
        font-weight: bold;
        font-size: 18px;
        border-bottom: 1px solid grey;
        margin-bottom: 14px;
        padding-bottom: 14px;
      }

      float: left;
      width: calc(50% - $grid-gutter-width);

      &.fw {
        width: 100%;
      }

      margin: 0 $grid-gutter-width $grid-gutter-width 0;
      display: flex;
      flex-direction: column;

      .heading {
        padding: $grid-gutter-width $grid-gutter-width $grid-gutter-width / 2 $grid-gutter-width;
      }

      .body {
        flex-grow: 1;
        padding: 0 $grid-gutter-width;
        overflow-x: scroll;
        min-height: 300px;

        wefra-mini-grid {
          min-height: 100%;
          min-width: 100%;

          .spinner-border {
            margin-top: 130px;
          }
        }
      }
    }
  }
}

wefra-ad-spendings-capture,
wefra-ad-spendings-upload {
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
  display: block;

  .image-container {
    pinch-zoom {
      display: block;
      width: auto !important;
      height: auto !important;

      img {
        width: 100%;
      }
    }
  }

  label {
    margin-bottom: 10px;
    margin-top: 20px;
    font-weight: bold;
  }
}

wefra-multi-form-field .dlselector,
dl.as-input {
  padding: 12px 16px;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #767472;
  background-color: white;
  border: var(--wefra-border-width) solid #767472;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

wefra-ad-spendings-capture {

  dl.edition {
    float: left;
    margin-right: $grid-gutter-width * 2;

    dd, dt {
      float: left
    }

    dt {
      width: 90px;
      clear: both;
    }
  }
}

wefra-monitor-component th.filter-form,
wefra-multi-form-field {
  dl {
    display: block;
    overflow: hidden;

    dt, dd {
      float: left;
      margin-right: 1em;
      margin-bottom: 0;
    }

    dt {
      clear: both;
    }
  }
}

wefra-monitor-component th.filter-form form {
  min-width: 600px;
}

wefra-ad-spending-editions-grid {
  display: block;
}


wefra-ad-spending-edition-show-grid {
  display: block;
  margin: 0 $grid-gutter-width 0 $grid-gutter-width;

  .medium, .printmedium, img {
    float: left;
    margin-right: $grid-gutter-width
  }
}

.generic-form-wrap {
  margin-left: $grid-gutter-width;
}

wefra-ad-spending-table {
  table {
    margin-top: $grid-gutter-width;

    tbody tr:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }

    th, td {
      padding: 3px;
    }

    th {
      border-bottom: 1px solid $wefra-black;
    }

    th, td {
      padding-right: 10px;

      &:last-child {
        text-align: right;
      }
    }

    td {
      vertical-align: top;

      &.advertisingform_id {
        white-space: nowrap;
      }

      &.multi {
        text-align: center;
      }

      &.price {
        width: 70px;
        white-space: nowrap;
      }

      &.page, &.price {
        text-align: right;
      }

      i-bs {
        margin-right: 5px;

        &[name='pencil'] {
          color: $green-700;

          &:hover {
            color: $green-400;
          }
        }

        &[name='x-circle'] {
          color: $red-700;

          &:hover {
            color: $red-400;
          }
        }

        &[name='files'] {
          color: $indigo-500;

          &:hover {
            color: $indigo-300;
          }
        }
      }
    }
  }
}

wefra-monitor-component {
  display: block;
  margin: 0 $grid-gutter-width;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  table {
    thead {
      background-color: $wefra-sand;
      position: sticky;
      top: 0;
    }

    th, td {
      background-color: $wefra-sand;
    }

    th {
      padding: 3px 5px;
    }

    td {
      padding: 0;
    }

    th.filter-form {

      .last {
        display: flex;
        clear: both;

        .duplicates {
          line-height: 2em;
          text-align: center;

          input {
            margin-right: 10px;
            margin-left: 10px;
          }

          span {
            display: inline-block;

          }

          svg {
            height: 24px;
            width: 24px;
          }
        }
      }

      .w-60, .w-40 {
        float: left;
      }

      .w-40 {
        width: 40%;
      }

      .w-60 {
        width: 60%;
      }

      input[type="text"] {
        width: 130px;
      }

      input.from, input.to {
        width: calc((100% - 100px) / 2);
      }

      input.magazine,
      input.publisher {
        width: calc(100% - 100px);
      }

      text-align: left;

      label {
        display: inline-block;
        float: left;

        &.fixedwith {
          width: 100px;
        }

        &.fixedwithright {
          width: 80px;
        }
      }
    }

    th, td {
      text-align: center;
      border: 1px solid $wefra-darkgrey;
      width: 2.2em;
      height: 2.2em;

      &.name {
        .color {
          height: calc(100% - 4px);
          width: 5px;
          margin-right: 5px;
        }

        .text {
          line-height: 2.2em;
          margin-left: 5px;
          white-space: nowrap;
          flex-grow: 1;
          text-align: left;
        }

        i-bs {
          cursor: pointer;
          text-align: right;
          line-height: 2.2em;
          margin-right: 10px;

          svg {
            height: 24px;
            width: 24px;
          }
        }
      }

      &.noborder {
        border: none;
      }
    }

    span.dis {
      width: 180px;
      border-top: 2px solid black;
      border-bottom: 2px solid black;
      margin: 0px 5px 0 5px;
      padding: 2px 10px;
      display: inline-block;
    }

    thead th.filter-form div.sumtile {
      min-width: 2ch;
      display: inline-block;
      padding: 4px 8px;
      margin-right: 4px;
      cursor: pointer;
    }

    thead th.filter-form div.sumtile,
    tbody tr td .entry, tbody tr td.name .color {
      &.scanned, &.null {
        background-color: $red-500;
        color: black;
      }

      &.capturing {
        background-color: $yellow-500;
        color: black;
      }

      &.completed {
        background-color: $green-500;
        color: white;
      }

      &.scanned, &.null, &.capturing, &.completed, &.future {
        &.future {
          background-color: $blue-500;
          color: white;
        }
      }
    }

    tbody {
      tr {
        td {
          .entry {

            width: 2em;
            height: 2em;

            color: white;
            display: block;
            margin: 0.1em;

            line-height: 2em;
            font-weight: bold;

            .dropdown-toggle::after {
              display: none;
            }
          }
        }

        td.name .color {
          margin: 0.1em;
          width: 7px;
          height: 2em;
          display: list-item;
        }

        &:hover td, td.hoverday {
          background-color: rgba(255, 255, 255, 0.1);

          &.noborder {
            background-color: rgba(255, 255, 255, 0);
          }
        }
      }
    }

    tbody {
      z-index: 888;
      position: relative;
    }

    thead {
      z-index: 999;

      th {
        top: 0;
      }

      th.hoverday {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
}

wefra-abstract-record-view {
  dl {
    dt, dd {
      display: block;
    }

    dt {
      //clear: both;
    }
  }
}
